import React from "react"
import { Multimedia } from "../Multimedia/Multimedia"
import post from '../../assets/images/poster.jpg';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/images/generators-aniamtion.json'

export const Banner = ({
  multimedia,
  interval,
  image,
  title,
  button1,
  button2,
  button3,
  subtitle,
  linkTopTitle
}) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet'
    }
  }

  return (
    <div className="Banner">
      <section className="content">
        {multimedia &&
          <div className="banner-video-big">
            <Multimedia src={multimedia.src} interval={interval} poster={post} className="video-multimedia" />
          </div>
        }
        <div className="center">
          <div className="content__ban">
            <div className={`content__banner ${image && "banner-bott"}`}>
              {/* <div className="sub-title-head">
                {button3}
                {linkTopTitle && (
                  <a
                    href={linkTopTitle.link?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="sub-head"
                  >
                    {linkTopTitle.label}
                  </a>
                )}
              </div> */}
              <div className="content__banner--title">{title}</div>
              {subtitle && (
                <div className="content__banner--sub-title">{subtitle}</div>
              )}
              <div
                className={`content__banner__cont-btn cont-btn ${image && "banner-geneators"
                  }`}
              >
                {button1}
                {button2}
              </div>
            </div>
            {image && (
              <div className="image-banner">
                <Lottie className="animation-bann" options={defaultOptions}
                  height={370}
                  width={700}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}
